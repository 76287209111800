<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <div class="my-swipe">
      <van-swipe :autoplay="5000" indicator-color="white">
        <van-swipe-item v-if="list.coverImg1">
          <img class="xq_img" :src="list.coverImg1" />
        </van-swipe-item>
        <van-swipe-item v-if="list.coverImg2">
          <img class="xq_img" :src="list.coverImg2" />
        </van-swipe-item>
        <van-swipe-item v-if="list.coverImg3">
          <img class="xq_img" :src="list.coverImg3" />
        </van-swipe-item>
        <van-swipe-item v-if="list.coverImg4">
          <img class="xq_img" :src="list.coverImg4" />
        </van-swipe-item>
      </van-swipe>
      <van-icon @click="back" class="f_ioc" name="arrow-left" />
    </div>
    <div class="xqbeij">
      <img src="../../assets/img/tutu.png" alt />
      <!-- <div class="jiage">
        <div class="jiage_A">
          <span class="jin">￥</span>
          <span>{{list.activityPrice}}</span>
        </div>
        <del class="jiage_b">
          <span class="jin">￥</span>
          <span>{{list.sellPrice}}</span>
        </del>
      </div>-->
      <div class="shijian">
        <!-- <div>距离结束仅剩</div>
          <div class="shi_b">
            <span class="jian">29</span>:
            <span class="jian">24</span>:
            <span class="jian">54</span>:
            <span class="jian">7</span>
        </div>-->
      </div>
    </div>
    <div class="xq_h1">
      <div class="jiage">
        <span class="jin">￥</span>
        <span>{{list.activityPrice}}</span>
        <!-- <del class="jiage_b">
          <span class="jin">￥</span>
          <span>{{list.sellPrice}}</span>
        </del>-->
      </div>
      <div class="cc_name">
        <span class="xq_h1_a">{{list.brand}}</span>
        <span class="xq_h1_a" v-if="list.model">/{{list.model}}</span>
        <span class="xq_h1_a" style="margin-left: 5px;" v-if="list.classify">{{list.classify}}</span>
        <span class="xq_h1_a" style="margin-left: 5px;" v-if="list.arr2">{{list.arr2}}</span>
        <span class="xq_h1_a" style="margin-left: 5px;" v-if="list.arr3">{{list.arr3}}</span>
        <span class="xq_h1_a" style="margin-left: 5px;" v-if="list.unit">{{list.unit}}</span>
      </div>
      <div class="shuo_b">
        <span
          class="ciri"
          v-if="list.arr1"
          style="color:#d16e22;border-color:#d16e22;"
        >{{list.arr1}}</span>
        <span class="ciri" v-if="list.arr3">{{list.arr3}}</span>
        <span class="ciri" v-if="list.unit">{{list.unit}}</span>
      </div>
    </div>

    <!-- <div class="xq_h2">
      <div class="yixuan">已选</div>
      <div class="xuan_a">
        <span class="xin_c">车型</span>
        <span class="xin_b">{{chexin.carBrand}}-{{chexin.carModel}}</span>
      </div>
      <div class="xuan_a">
        <span class="xin_c left">数量</span>
        <span class="xin_b left">
          <van-stepper v-model="value" />
        </span>
      </div>
      <div class="xuan_a">
        <span class="xin_c">物流</span>
        <span class="xin_b">

          <span class="ciri">次日达</span>现货，预计明天可送达
        </span>
      </div>
      <div class="xuan_a" style="margin-top: 16px;">
        <span class="xin_c left" style="line-height: 13px;">门店</span>
        <span class="xin_b left">
          <img class="mendiantu" v-if="shangjia.doorPic" :src="shangjia.doorPic" alt />
          <img class="mendiantu" v-else src="../../assets/img/mendian.png" alt />
        </span>
        <div class="left dian_ha">
          <div class="dian_h1">{{shangjia.name}}</div>
          <div class="dian_h2">
            {{juli}}km
            <span>免运费</span>
          </div>
          <div class="dian_h3">{{shangjia.city}}{{shangjia.district}}{{shangjia.address}}</div>
        </div>
      </div>
    </div>-->
    <div class="canpin">产品说明</div>
    <van-row class="msgHtmlBox ql-snow ql-editor" v-html="list.content"></van-row>
  </div>
</template>

<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      jd: JSON.parse(localStorage.getItem("JD")),
      wd: JSON.parse(localStorage.getItem("WD")),
      list: {},
      active: "0",
      value: 1,
      Aswitch: 1,
      juli: 0,
      id: this.$route.query.id
    };
  },
  methods: {
    onLoad() {
      const data = {
        id: this.id
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      userModel
        .details(data)
        .then(e => {
          loading.clear();
          if (e.data.norm != null) {
            var str = e.data.classify.indexOf("/") != -1;
            console.log(str)
            if (str == true) {
              var norm = e.data.norm.split("/");
              let arr = norm[1].split("");
              arr.splice(2, 0, "/");
              norm[1] = arr.join("");
              e.data.arr1 = norm[0];
              e.data.arr2 = norm[1];
              e.data.arr3 = norm[2];
            }else{
              e.data.arr1 = e.data.norm
            }
          }
          this.list = e.data;
          console.log(this.list);
        })
        .catch(() => loading.clear());
    },

    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.Aswitch = this.$route.query.Aswitch;
    this.onLoad();
    console.log(this.$route.query);
  },
  mounted() {

  }
};
</script>

<style lang="less" scoped>
.ord_lis .order_list:nth-of-type(n + 2) {
  margin-top: 10px;
}
.my-swipe {
  position: relative;

  //   height: 362px;
}
.xq_img {
  width: 260px;
  margin: auto;
  height: 260px;
}
.van-swipe-item {
  display: flex;
}
.xqbeij {
  transform: translateZ(0);
  // position: absolute;

  // left: 0;
  width: 100%;
  //   height: 40px;
  // z-index: 99;
}
.f_ioc {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  margin-top: 10px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  background-color: rgba(31, 45, 61, 0.3);
  border-radius: 50%;
}
.canpin {
  padding: 0 15px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 8px;
}
.jiage {
  font-size: 22px;
  color: #f23756;
  padding: 2px 0 6px 0;
}
.jin {
  font-size: 12px;
}
.jiage_A {
  font-size: 23px;
  font-weight: bold;
  line-height: 14px;
}
.shijian {
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: 8px;
  text-align: center;
}
.shuo_b {
  overflow: hidden;
  margin-top: 10px;
}
.xq_h1 {
  background: #fff;
  overflow: hidden;
  padding: 12px 15px 20px 15px;

  border-bottom: 8px solid #f5f5f5;
}
.cc_name {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.xq_h2 {
  padding: 10px 15px 15px 15px;
  border-bottom: 8px solid #f5f5f5;
}
.xin_c {
  color: #999;
}
.ciri {
  float: left;
  color: #2121c0;
  border: 1px solid #2121c0;
  height: 14px;
  line-height: 13px;
  font-size: 12px;
  transform: scale(0.83);
  padding: 0 1px;
  border-radius: 3px;
}
.mendiantu {
  width: 80px;
  height: 55px;
}
.dian_h1 {
  font-size: 15px;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
}
.dian_h3 {
  color: #666;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.van-goods-action-button--last {
  border-radius: 20px;
}
.dian_h2 {
  color: #666;
  line-height: 14px;
  margin-top: 8px;
}
.dian_ha {
  margin-left: 10px;
  width: 210px;
}
.xin_b {
  margin-left: 12px;
  font-size: 13px;
}
.xuan_a {
  font-size: 13px;
  overflow: hidden;
  //   line-height: 35px;
  margin: 5px 0;
}
.ciri:nth-of-type(n + 2) {
  margin-left: 5px;
}
.yixuan {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}
.xq_h1_a {
  line-height: 12px;
  font-size: 15px;
  font-weight: bold;
}

.jian {
  display: inline-block;
  background: #fff;
  color: #f23756;
  // padding: 0 2px;
  width: 18px;
  text-align: center;
  border-radius: 2px;
}
.shi_b {
  line-height: 14px;
}
.jiage_b {
  line-height: 14px;
  font-size: 16px;
  color: #666;
}
.xqbeij img {
  width: 100%;
}
.ql-editor {
  margin: 2px 15px;
  overflow: hidden;
  font-size: 14px;
  // margin-top: 15px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
.ql-editor p img {
  width: 100%;
  outline-width: 0px;
  vertical-align: top;
}
</style>
<style scoped>
.msgHtmlBox >>> img {
  width: 100%;
}
/* .msgHtmlBox >>> strong {
  font-weight: bold;
} */
</style>